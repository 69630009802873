.profile-container{
    overflow: auto;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: var(--small);
    box-sizing: border-box;
    gap: var(--large);
}
.user-details-container,
.org-details-container{
    box-shadow: 0px 4px 4px var(--pulse-shadow);
    width: 320px;
    border-radius: var(--border-radius);
    overflow: auto;
    background-color: var(--pulse-background-2);
    text-align: left;
    margin-top: var(--large);
    display: block;
    height: fit-content;
    position: relative;
}
.profile-title{
    font-size: var(--font-large-xxx);
    font-weight: 600;
    color: var(--pulse-text-secondary);
    background: var(--gradient-background);
    padding: var(--large);
    color: #f9c4d7;
    text-align: center;
}
.user-edit-container,
.detail-list{
    padding: var(--medium) var(--large);
    height: 540px;
    overflow: auto;
}
.details-item{
    padding: var(--medium-xx) 0px;
}
.details-item:not(:last-child){
    
    border-bottom: 1px solid var(--pulse-text-secondary);
}
.detail-title{
    font-weight: 500;
    color: var(--link-text);
}
.details-value{
    font-size: var(--font-medium);
    margin-top: var(--small-xxx);
    color: var(--pulse-text);
}
.edit-icon-container{
    position: absolute;
    right: var(--small);
    top: 95px;
    box-sizing: border-box;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* color: var(--pulse-text-secondary); */
    background: var(--grey-button);
    cursor: pointer;
}
.edit-icon-container:hover{
    background: var(--grey-button-hover);
}

.edit-btn-container{
    display: flex;
    gap: var(--medium);
}

.common-error{
    color: var(--mandatory);
    font-size: var(--font-small);
}

@media (max-width:400px)  {
    .org-details-container{
        width: 280px;
    }
}

@media (max-width:300px)  {
    .org-details-container{
        width: 190px;
    }
}