.support-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    height: 80%;
    text-align: left;
}
.doc-link{
    text-decoration: none;
    font-size: var(--font-medium);
    color: var(--link-text);
    padding: var(--small);
    box-sizing: border-box;
    margin-top: var(--medium-xx);
    display: block;
    background-color: var(--pulse-background-link);
    border-radius: var(--border-radius);
    width: fit-content;
    display: flex;
    align-items: center;
    gap: var(--small-xx);
}
.contact-inner-content,
.doc-inner-content{
    font-size: var(--font-medium);
    color: var(--pulse-text);
}
.contact-inner-content{
    text-align: center;
}
.doc-title{
    font-weight: 600;
    font-size: var(--font-large);
}

.contact-section{
    margin-bottom: var(--medium-xx);
}

.contact-label{
    font-weight: 600;
    margin-bottom: var(--small-xx);
    font-size: var(--font-large);
}
.contact-value{
    color: var(--pulse-primary);
    font-size: var(--font-small);
    display: flex;
    align-items: center;
    gap: var(--small-xx);
    justify-content: center;
}