.infocard-container{
    box-shadow: 0px 4px 4px var(--pulse-shadow);
    min-width: 380px;
    border-radius: var(--border-radius);
    overflow: auto;
}
.infocard-title{
    min-height: 200px;
    background: var(--gradient-background);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f9c4d7;
    font-weight: 700;
    gap: 10px;
    font-size: var(--font-large-xx);
}
.infocard-child-container{
    overflow: auto;
    height: 170px;
    padding: var(--medium);
    background-color: var(--pulse-background-2);
}

@media (max-width:400px)  {
    .infocard-container{
        min-width: 280px;
    }
}

@media (max-width:300px)  {
    .infocard-container{
        min-width: 190px;
    }
}