.payment-container{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: var(--pulse-text);
    flex-direction: column;
}
.pay-title{
    font-size: var(--font-h1);
}
.pay-card{
    box-shadow: 0px 4px 4px var(--pulse-shadow);
    width: 320px;
    border-radius: var(--border-radius);
    overflow: auto;
    background-color: var(--pulse-background-2);
    padding: var(--large);
}
.pay-card-title{
    font-weight: 700;
}

.pay-amt{
    font-size: var(--font-h1);
    margin: var(--large) 0px;
    font-weight: 700;
    color: var(--pulse-text-secondary);
}
.pay-card-container{
    flex: 1;
    display: flex;
    align-items: flex-start;
    padding-top: var(--large);
    margin-top: var(--large);
}