.billing-title,
.plan-title{
    font-size: var(--font-h1);
    color: var(--pulse-text);
}
.billing-description,
.plan-description{
    margin-top: var(--small);
    color: var(--pulse-text-secondary);
    font-weight: 300;
}
.plan-card-section{
    display: flex;
    gap: var(--small);
    flex-wrap: wrap;
    justify-content: center;
    margin-top: var(--medium);
}
.plan-card-conatiner{
    box-shadow: 0px 4px 4px var(--pulse-shadow);
    width: 380px;
    border-radius: var(--border-radius);
    background-color: var(--pulse-background-2);
    text-align: left;
    padding: var(--large);
    display: flex;
    flex-direction: column;

}

@media (max-width:400px)  {
    .plan-card-conatiner{
        width: 280px;
    }
}

@media (max-width:300px)  {
    .plan-card-conatiner{
        width: 190px;
    }
}


.pc-title{
    color: var(--link-text);
    background-color: var(--pulse-background-link);
    border-radius: var(--border-radius);
    padding: var(--small);
    box-sizing: border-box;
    font-weight: 700;
    font-size: var(--font-large);
    margin-bottom: var(--small);
}
.pc-description{
    color: var(--pulse-text-secondary);
    font-weight: 300;
    font-size: var(--font-medium);
    margin: var(--medium) 0px;
}
.pc-price{
    font-weight: 900;
    font-size: var(--font-h1);
    color: var(--pulse-text);
    
    padding-bottom: var(--small);
    
}
.pc-price-unit{
    color: var(--pulse-text-secondary);
    font-weight: 300;
    font-size: var(--font-small);
    
}
.features-section{
    padding: var(--small) 0px;
    border-top: 1px solid var(--pulse-text-secondary);
    color: var(--pulse-text-secondary);
    flex: 1;
}
.features-title{
    font-size: var(--font-large-x);
    font-weight: 700;
}
.feature-item{
    display: flex;
    margin: var(--medium-xx) 0px;
    gap: var(--small);
    align-items: center;
}
.contact-msg{
    margin-bottom: var(--medium);
    font-weight: 700;
    color: var(--pulse-text);
}

.current-usage-container{
    box-shadow: 0px 4px 4px var(--pulse-shadow);
    border-radius: var(--border-radius);
    background-color: var(--pulse-background-2);
    max-width: 700px;
    margin: auto;
    margin-top: var(--medium);
    display: flex;
    padding: var(--medium);
}
.cu-left{
    flex: 1;
    text-align: left;
}
.cu-title{
    display: flex;
    align-items: center;
    font-weight: 700;
    gap: var(--small-xx);
    color: var(--pulse-text-secondary);
}
.cu-details{
    color: var(--link-text);
    background-color: var(--pulse-background-link);
    border-radius: var(--border-radius);
    padding: var(--small-xx) var(--small);
    margin-top: var(--small);
    width: fit-content;
    font-size: var(--font-medium);
    display: flex;
    gap: var(--small-xx);
    align-items: center;
    cursor: pointer;
}
.cu-right{
    font-size: var(--font-h1);
    display: flex;
    align-items: center;
    font-weight: 600;
    color: var(--pulse-text-secondary);
}
.cu-unit{
    font-size: var(--font-medium);
}