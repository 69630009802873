.pulse-button{
    width: 100%;
    border: 0px;
    padding: var(--small);
    border-radius: var(--border-radius);
    margin-top: var(--medium);
    font-weight: 600;
    cursor: pointer;
}
.pulse-button:hover{
    box-shadow: 0px 8px 15px var(--pulse-shadow);
}
.primary{
    background-color: var(--pulse-primary);
    color: aliceblue;
}
.cancel{
    background-color: var(--grey-button);
}