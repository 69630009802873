.wizard-container{
    display: flex;
    width: 100%;
    height: 100vh;
    background-color: var(--pulse-background);
}
.wizard-inner-container{
    flex: 1;
    display: flex;
    flex-direction: column;
}
.left-panel-container{
    display: flex;
    flex-direction: column;
    box-shadow: 0px 8px 15px var(--pulse-shadow);
    width: 60px;
    align-items: center;
    background-color: var(--pulse-background-2);
    z-index: 10;
}
.left-panel-logo{
    height: 20%;
}
.left-panel-logo img{
    width: 40px;
    margin-top: 50%;
}
.left-panel-nav-section{
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.icon-container{
    padding: var(--small) 0px;
    cursor: pointer;
}
.top-icon-container:hover,
.icon-container:hover{
    background-color: var(--icon-hover);
}
.nav-icon{
    color: var(--pulse-text-secondary);
    font-size: var(--icon-size);
}
.selected-icon{
    background-color:  var(--icon-hover);
}
.selected-icon .nav-icon{
 color: var(--pulse-primary);
}
.top-icons-wrapper{
    display: flex;
}
.top-license-wrapper{
    justify-content: left;
    display: flex;
    align-items: center;
    flex: 1;
}
.licence-id{
    margin-left: var(--large);
    color: var(--pulse-text);
}
.license-label{
    font-weight: 700;
    color: var(--pulse-text-secondary);
}
.top-panel-container{
    display: flex;
    background-color: var(--pulse-background-2);
    z-index: 9;
    box-shadow: 0px 2px 8px var(--pulse-shadow);
}
.top-icon-container{
    padding: var(--medium-xxx) var(--small);
    cursor: pointer;
    display: flex;
    align-items: center;
}
.profile-img{
    height: var(--icon-size);
    width: var(--icon-size);
    border-radius: 50%;
    border: 1px solid var(--pulse-text-secondary);
}

.outlet-container{
    padding: var(--large);
    box-sizing: border-box;
    flex: 1;
    overflow: auto;
}
.wizard-content-title{
    text-align: left;
    font-size: var(--font-h2);
    font-weight: 700;
    color: var(--pulse-text);
}