.login-container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-color: var(--pulse-background);
}
.login-section{
    width: 400px;
    box-sizing: border-box;
    position: relative;
    background-color: var(--pulse-background-form);
    border-radius: var(--border-radius);
    padding: var(--large);
    border: 1px solid var(--pulse-primary);
}
.tag-line{
    color: var(--pulse-text);
}
.login-image-container{
    margin-bottom: var(--medium);
}
.login-image-container img{
    height: 50px;
}
.google-login{
    display: flex;
    align-items: center;
    gap: 7px;
    font-size: var(--font-medium);
    font-weight: 600;
    justify-content: center;
    background: var(--grey-button);
    padding: var(--small);
    border-radius: var(--border-radius);
    margin: auto;
    margin-top: var(--medium);
    cursor: pointer;
}
.google-login:hover{
    background-color: var(--grey-button-hover);
}