.pulse-input-container{
    margin: var(--medium-xx) 0px;
    text-align: left;

}
.pulse-input-label{
    font-size: var(--font-small);
    margin-bottom: var(--small-xxx);
    font-weight: 600;
    text-transform: capitalize;
    color: var(--pulse-text);
}
.pulse-input-label span{
    color: var(--pulse-primary);
}
.pulse-input-field{
    width: 100%;
    border-radius: var(--border-radius);
    border: 1px solid var(--input-border);
    padding: var(--small-xx);
    box-sizing: border-box;
    background-color: var(--pulse-background-2);
    color: var(--pulse-text);
}
.input-error{
    border-color: var(--mandatory);
}
.input-error-msg{
    color: var(--mandatory);
    font-size: var(--font-small-x);
    position: absolute;
}