.details-col {
    cursor: pointer;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.arrow-btn {
    background-color: var(--pulse-background-link);
    color: var(--link-text);
    width: 30px;
    height: 30px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.table-container {
    text-align: left;
}