body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  --pulse-primary: #dd0c4e;
  --pulse-secondary: #281d50;
  --pulse-secondary-light: #aa95f069;
  --pulse-sub-title: #999999;
  --gradient-background: linear-gradient(to bottom,
      #dd0c4e, #281d50);
  --border-radius: 5px;
  --small-xxx: 4px;
  --small-xx: 6px;
  --small: 10px;
  --medium-xxx: 14px;
  --medium-xx: 16px;
  --medium-x: 18px;
  --medium: 20px;
  --large: 30px;
  --font-small-x: 10px;
  --font-small: 12px;
  --font-medium: 14px;
  --font-large: 16px;
  --font-large-x: 18px;
  --font-large-xx: 20px;
  --font-large-xxx: 22px;
  --font-h1: 40px;
  --font-h2: 30px;
  --grey-button: #c2bfbf;
  --grey-button-hover: #cdc9c9;
  --mandatory: red;
  --input-border: #a3a1ab4f;
  --background-primary: #dd0c4e15;

  --icon-size: 24px;
  --gradient-background: linear-gradient(90deg, rgb(140 43 74) 0%, rgba(3,3,50,1) 100%);
  
}

.light {
  --pulse-background: rgb(221 12 78 / 2%);
  --pulse-background-2: #dadadc75;
  --pulse-background-form: #de16550d;
  --pulse-background-link: #de165530;
  --pulse-background-bright: #fff;
  --pulse-background-modal: #0808088a;

  --pulse-text-secondary: #281d50;
  --icon-hover: #281d5022;
  --pulse-shadow: rgba(0, 0, 0, 0.2);
  --pulse-text: #000;
  --link-text: #a70b3c;
}

.dark {
  --pulse-background: #18181ff2;
  --pulse-background-2: #0c0c0f;
  --pulse-background-form: #7c0d30c8;
  --pulse-background-link: #7c0d30c8;
  --pulse-background-bright: #000;


  --pulse-text-secondary: #a8a4bb;
  --icon-hover: #c2b7ea22;
  --pulse-shadow: rgba(36, 36, 36, 0.788);
  --pulse-text: #fbf7f7;
  --link-text: #f4aac1;
  --pulse-background-modal: #faf7f736;
}