.loader-container{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 99;
    background-color: rgba(9, 8, 8, 0.265);
    display: flex;
    align-items: center;
    justify-content: center;
}
.loader-content{
    display: flex;
    align-items: center;
}
.lds-hourglass {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-hourglass:after {
    content: " ";
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin: 8px;
    box-sizing: border-box;
    border: 32px solid var(--pulse-primary);
    border-color: var(--pulse-secondary) transparent var(--pulse-primary) transparent;
    animation: lds-hourglass 1.2s infinite;
}

@keyframes lds-hourglass {
    0% {
        transform: rotate(0);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    50% {
        transform: rotate(900deg);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    100% {
        transform: rotate(1800deg);
    }
}