.registration-container{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: var(--pulse-background);
}
.registration-content{
    border: 1px solid var(--pulse-primary);
    border-radius: var(--border-radius);
    padding: var(--large);
    background-color: var(--pulse-background-form);
}
.registration-head-section{
    text-align: left;
    margin-bottom: var(--medium);
}
.registration-title{
    font-size: var(--font-large-x);
    font-weight: 700;
    margin-bottom: var(--small-xxx);
    color: var(--pulse-text);
}
.registration-sub-title{
    font-size: var(--font-small);
    color: var(--pulse-sub-title);
}