.common-modal-container{
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: var(--pulse-background-modal);
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
}

.common-modal-inner{
    background-color: var( --pulse-background-bright);
    min-width: 350px;
    max-width: 70%;
    min-height: 150px;
    border-radius: var(--border-radius);
    box-shadow: 0px 0px 10px var(--pulse-shadow);
    padding: var(--large);
    display: flex;
    flex-direction: column;
    position: relative;
}

.close-btn{
    position: absolute;
    top: 14px;
    right: 14px;
    font-size: var(--font-large-xx);
    cursor: pointer;
    color: var(--pulse-text);
}