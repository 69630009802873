.cluster-title{
    margin: var(--medium) 0px;
    text-align: left;
    color: var(--pulse-text-secondary);
    font-weight: 600;
    font-size: var(--font-large-x);
}
.back-button{
    text-align: left;
    margin: var(--small) 0px;
    display: flex;
    align-items: center;
    gap: var(--small-xx);
    cursor: pointer;
    font-size: var(--font-medium);
    color: var(--pulse-secondary);
    padding: var(--small) var(--medium);
    box-sizing: border-box;
    background-color: var(--pulse-secondary-light);
    border-radius: var(--border-radius);
    width: fit-content;
}

.cluster-item-container{
    margin: var(--medium) 0px;
    margin: var(--large) 0px;
    padding: var(--medium);
    background-color: var(--pulse-background-2);
    border-radius: var(--border-radius);
}

.cluster-subtitle{
    font-weight: 600;
    font-size: var(--font-large-x);
    color: var(--pulse-primary);
    margin-bottom: var(--medium-xx);
    text-align: left;
}

.cluster-container,
.cluster-detail-container{
    padding: var(--small) var(--medium);
}