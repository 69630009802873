.modal-container{
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: var(--pulse-background-modal);
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-inner{
    background-color: var( --pulse-background-bright);
    min-width: 50%;
    max-width: 70%;
    min-height: 150px;
    border-radius: var(--border-radius);
    box-shadow: 0px 0px 10px var(--pulse-shadow);
    padding: var(--large);
    display: flex;
    flex-direction: column;
}
.msg-section{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: var(--pulse-text);
}
.btn-section{
    display: flex;
    justify-content: end;
}
.modal-btn{
    width: 20%;
    margin-top: 0px;
}