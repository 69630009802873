.manage-user-container{
    text-align: left;
}
.user-section-container{
    margin: var(--large) 0px;
    padding: var(--medium);
    background-color: var(--pulse-background-2);
    border-radius: var(--border-radius);
}
.user-section-title{
    font-weight: 600;
    font-size: var(--font-large-x);
    color: var(--pulse-primary);
    flex: 1;
}
.user-top-bar{
    display: flex;
    align-items: center;
    margin-bottom: var(--medium-xx);
}
.invite-btn{
    max-width: 135px;
    margin-top: 0px;
}

.invite-user-title{
    font-size: var(--font-h2);
    color: var(--pulse-text-secondary);
    font-weight: 700;
}